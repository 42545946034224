import {createWebHashHistory, createRouter} from "vue-router";
import layout from "../components/layout";
import {ElMessage} from "element-plus";

const routes = [
    {
        path: "/",
        redirect: "index",
        meta: {title: "金融计算器"},
        component: layout,
        children: [
            {
                path: "userinfo",
                name: "userinfo",
                component: () => import("../views/user"),
                meta: {title: "个人中心"},
            },
            {
                path: "index",
                name: "index",
                component: () => import("../views/index"),
                meta: {title: "首页"},
            },
        ],
    },
    {
        path: "/FinancialCalculator",
        component: () =>
            import("../views/Financialculator/FinancialCalculator.vue"),
        meta: {title: "金融计算器", keepAlive: true},
    },
    {
        path: "/insurance",
        component: () => import("../views/insurance"),
        meta: {title: "保险计算器"},
    },
    {
        path: "/enquiry",
        name: "enquiry",
        component: () => import("../views/enquiry"),
        meta: {title: "汽车询价器"},
    },
    {
        path: "/enquirydetail",
        component: () => import("../views/enquiry/enquirydetail.vue"),
        meta: {title: "车辆详情"},
    },
    {
        path: "/shareFinancialculator/:priceTicket/:purchaseTax/:PriceInsurance/:otherExpenses/:UetoCost/:TotalCost/:DownPayment/:loanAmount/:StagingTimeNum/:monthlyInstallmentPayment",
        name: "shareFinancialculator",
        component: () =>
            import("../views/Financialculator/shareFinancialCalculator.vue"),
        meta: {title: "价格明细"},
    },
    {
        path: "/clientfinancial",
        name: "clientfinancial",
        component: () => import("../views/clientfinancial"),
        meta: {title: "客户金融"},
    },
    {
        path: "/configuration",
        name: "configuration",
        component: () => import("../views/enquiry/configuration"),
        meta: {title: "车型详情"},
    },
    // {
    //     path: "/login",
    //     name: 'login',
    //     component: () => import("../views/views/login/index.vue"),
    //     meta: {title: "登录"},
    // },
    {
        path: "/brandlist",
        name: "brandlist",
        component: () => import("../views/enquiry/brandlist.vue"),
        meta: {title: "品牌列表"},
    },
    {
        path: "/serieslist",
        name: "serieslist",
        component: () => import("../views/enquiry/serieslist.vue"),
        meta: {title: "车系列表"},
    },
    {
        path: "/vehiclelist",
        name: "vehiclelist",
        component: () => import("../views/enquiry/vehiclelist.vue"),
        meta: {title: "车型列表"},
    },
    //车库系列列表路由
    {
        path: "/cartInventory",
        name: "cartInventory",
        component: () => import("../views/cartInventory/index.vue"),
        meta: {title: "车辆库存"},
    },
    {
        path: "/cartSeries",
        name: "cartSeries",
        component: () => import("../views/cartInventory/cartSeries.vue"),
        meta: {title: "车系列表"},
    },
    {
        path: "/cartModel",
        name: "cartModel",
        component: () => import("../views/cartInventory/cartModel.vue"),
        meta: {title: "车型列表"},
    },
    {
        path: "/cartUnique",
        name: "cartUnique",
        component: () => import("../views/cartInventory/cartUnique.vue"),
        meta: {title: "车架号列表"},
    },
    {
        path: "/cartDetail",
        name: "cartDetail",
        component: () => import("../views/cartInventory/cartDetail.vue"),
        meta: {title: "车架号详情"},
    },
    {
        path: "/cartSearch",
        name: "cartSearch",
        component: () => import("../views/cartInventory/Search/index.vue"),
        meta: {title: "搜索"},
    },
    {
        path: "/search",
        name: "search",
        component: () => import("../views/search"),
        meta: {title: "搜索"},
    },
    {
        path: "/AwardCar",
        name: "AwardCar",
        component: () => import("../views/AwardCar"),
        meta: {title: "奖励车型"},
    },
    {
        path: "/energy",
        name: "energy",
        component: () => import("../views/Newenergy"),
        meta: {title: "新能源"},
    },
    {
        path: "/newnnergyserieslist",
        name: "newnnergyserieslist",
        component: () => import("../views/Newenergy/newnnergyserieslist.vue"),
        meta: {title: "新能源车系列表"},
    },
    {
        path: "/userdCar",
        name: "userdCar",
        component: () => import("../views/usedCar"),
        meta: {title: "二手车"},
    },
    {
        path: "/userdCarserieslist",
        name: "userdCarserieslist",
        component: () => import("../views/usedCar/userdCarserieslist.vue"),
        meta: {title: "二手车"},
    },
    {
        path: "/m-askStatistics",
        name: "m-askStatistics",
        component: () => import("../views/m-askStatistics/index.vue"),
        meta: {title: "询价排名"},
    },
    {
        path: "/customer_need",
        name: "customer_need",
        component: () => import("../views/customerNeed/index.vue"),
        meta: {title: "客户需求"},
    },
    // {
    //     path: "/customerUpload",
    //     name: "customerUpload",
    //     component: () => import("../views/customerNeed/upload.vue"),
    //     meta: {title: "客户文件上传"},
    // },
    {
        path: "/customerList",
        name: "customerList",
        component: () => import("../views/customerNeed/customerList.vue"),
        meta: {title: "客户列表"},
    },
    {
        path: "/pageByUser",
        name: "pageByUser",
        component: () => import("../views/pageByUser/index.vue"),
        meta: {title: "借还钥匙列表"},
    },
    {
        path: "/returnKey",
        name: "returnKey",
        component: () => import("../views/pageByUser/returnKey.vue"),
        meta: {title: "请核对车辆信息"},
    },
    {
        path: "/outboundSystem",
        name: "outboundSystem",
        component: () => import("../views/outboundSystem/index.vue"),
        meta: {title: "自动外呼"},
    },
    {
        path: "/test",
        name: "test",
        component: () => import("../views/test/index.vue"),
        meta: {title: "测试页面"},
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({y: 0}),
    routes: routes,
});
router.beforeEach(async (to, from, next) => {
    if (to.matched.length === 0) {
        // 如果未匹配到路由
        next("/");
    } else {
        next();
    }

});

// 后置
router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
});

export default router;
