<script setup>
import { watch } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const active = ref("index");
watch(
  () => route.name,
  (newVal) => {
    active.value = newVal;
  },
  { deep: true, immediate: true }
);
function toUrl(url) {
  router.replace(url);
}
</script>

<template>
  <router-view></router-view>
  <van-tabbar v-model="active">
    <van-tabbar-item @click="toUrl('/')" name="index" icon="home-o"
      >首页</van-tabbar-item
    >
    <van-tabbar-item
      @click="toUrl('/userinfo')"
      name="userinfo"
      icon="friends-o"
      >我的</van-tabbar-item
    >
  </van-tabbar>
</template>

<style scoped></style>
